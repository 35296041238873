import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Form as FinalForm, Field } from 'react-final-form'
import { Flex } from 'reflexbox'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Clickable, UnowLoader } from '@unowmooc/react-ui-kit'
import { Checkbox } from '@unowmooc/inputs'
import { omitProperties } from '@unowmooc/utils/form'
import { Text } from '@unowmooc/text'
import { colors, rgba, transitions } from 'styles-resources'
import { mq } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import 'quill-mention'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'
import InputFile from './InputFile'
import InputFilev2 from './InputFile/indexv2'
import Wysiwyg from './Wysiwyg'
import { mentionStyle, sourceMention } from './Mention'
import { useThreadContext } from '../../Context/ThreadContext'

const GET_ALL_USERS_FOR_TAG = gql`
  query usersForTag($sessionId: ID!, $userId: ID!) {
    usersForTag(sessionId: $sessionId, userId: $userId) {
      items {
        id
        fullName
        slug
        isAnimator
        weight
      }
    }
  }
`

const embedFormStyle = css`
  background-color: ${rgba(colors.mischka, 0.3)};
  border-radius: 4px;
  border: 1px solid ${colors.mischka};

  ${mq.sm(css`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `)};
`

const StyledForm = styled.form`
  display: flex;
  background-color: ${colors.white};
  ${({ embed }) => embed && embedFormStyle};
`

const Fields = styled.div`
  position: relative;
  width: 100%;
`

const StyledWysiwyg = styled(Wysiwyg)`
  width: 100%;
  ${mentionStyle}
`

const useInputFileV2 = true
const usedInputFile = useInputFileV2 ? InputFilev2 : InputFile

const StyledInputFile = styled(usedInputFile)`
  position: absolute;
  top: 10px;
  right: 10px;
`

const SubmitButton = styled(Clickable)`
  width: 100px;
  flex-shrink: 0;
  text-align: center;
  border-left: 1px solid ${colors.mischka};
  transition: ${transitions.color};
  color: ${({ disabled }) => (disabled ? colors.mischka : colors.unowGreen)};
`

const WysiwygFieldComp = ({ autofocus, placeholder, wysiwygOffset, dataUsers }) => {
  const users = _.map(_.uniqBy(_.get(dataUsers, 'usersForTag.items', []), 'id'), value => {
    const userToAdd = {}

    userToAdd.id = value.id
    userToAdd.value = value.fullName

    // l'id -1 correspondant au tag "tout le monde", on ne met pas de lien vers un profil
    if (value.id !== '-1') {
      userToAdd.link = I18nProvider.getLinkRoute('/profile/{slug}/{id}', { slug: value.slug, id: value.id })
    }

    userToAdd.trackingId = 'session.click_on_profile_page'
    userToAdd.trackingValues = JSON.stringify({
      from: 'Comment Wysiwyg',
    })

    userToAdd.isAnimator = value.isAnimator ? value.fullName : ''

    return userToAdd
  })

  const source = sourceMention(users)

  const inputField = ({ input }) => (
    <StyledWysiwyg
      {...input}
      autofocus={autofocus}
      placeholder={placeholder}
      offset={wysiwygOffset}
      formats={['bold', 'link', 'mention']}
      modules={{
        toolbar: ['bold', 'link'],
        mention: {
          dataAttributes: ['trackingId', 'trackingValues'],
          mentionDenotationChars: ['@'],
          isolateCharacter: true,
          linkTarget: '_blank',
          allowedChars: /^[A-Za-z\sàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ\-çÇßØøÅåÆæœ]*$/,
          source,
        },
      }}
    />
  )

  inputField.propTypes = { input: PropTypes.shape().isRequired }

  return inputField
}

const Form = ({
  user,
  session,
  initialValues,
  onSubmit,
  placeholder,
  embed,
  wysiwygOffset,
  autofocus,
  showMarkAsResolved,
  showIsAnonymous,
  className,
  hasAnonymousCommentInThread,
  canEditFile,
}) => {
  const [formInitialValues] = useState(initialValues)
  const { data } = useQuery(GET_ALL_USERS_FOR_TAG, {
    variables: {
      sessionId: session.id,
      userId: user.id,
    },
  })

  // Si data change alors on appel à nouveau cette fontion
  // (WysiwygFieldComp n'est pas un composant donc React.memo n'est pas utilisable)
  // pour mettre à jour les mentions
  const ReactQuillField = useMemo(() => WysiwygFieldComp({ autofocus, placeholder, wysiwygOffset, dataUsers: data }), [
    data,
  ])

  const { setIsFormDirtyAndValid } = useThreadContext()

  return (
    <FinalForm
      subscription={{
        valid: true,
        submitting: true,
        dirty: true,
        values: true,
      }}
      initialValues={formInitialValues}
      onSubmit={(params, form) =>
        onSubmit(
          omitProperties(params, ['file.preview', 'file.secureUrl', 'file.signature', 'file.policy', 'markAsResolved']),
          form,
          params,
        )
      }
      validate={({ text, file }) => {
        // file upload in error or in progress
        if (file && !file.fileStackId) {
          return I18nProvider.formatMessage({ id: 'validators.required_field' })
        }

        // At least one field must be filled in
        return text || file ? undefined : I18nProvider.formatMessage({ id: 'validators.required_field' })
      }}
      render={({ handleSubmit, submitting, dirty, valid }) => {
        setIsFormDirtyAndValid(valid && dirty)

        return (
          <StyledForm onSubmit={handleSubmit} embed={embed} className={className}>
            <Fields>
              <Field name="text" parse={value => (value === '<p><br></p>' ? '' : value)} component={ReactQuillField} />
              {canEditFile && <Field name="file" allowNull render={({ input }) => <StyledInputFile {...input} />} />}
              {showMarkAsResolved && (
                <>
                  {hasAnonymousCommentInThread && (
                    <Text
                      color="red"
                      style={{ padding: '0px 20px 0px' }}
                      i18nKey="expert.comments.has_anonymous_comment_in_thread_help"
                    />
                  )}
                  <Field
                    name="markAsResolved"
                    render={value => (
                      <Flex sx={{ padding: '10px 20px 0px' }}>
                        <Checkbox
                          defaultChecked={value.input.value}
                          {...value.input}
                          message={<Text color="shipGray" i18nKey="expert.comments.mark_as_done_after_answer" />}
                        />
                      </Flex>
                    )}
                  />
                </>
              )}
              {showIsAnonymous ? (
                <Field
                  name="isAnonymous"
                  type="checkbox"
                  render={value => (
                    <Flex sx={{ padding: '0px 20px 0px' }}>
                      <Checkbox {...value.input} message={<Text color="shipGray" i18nKey="comment.is_anonymous" />} />
                    </Flex>
                  )}
                />
              ) : (
                <Field name="isAnonymous">{() => null}</Field>
              )}
            </Fields>
            <SubmitButton type="submit" disabled={!(valid && dirty) || submitting}>
              {submitting ? <UnowLoader /> : <>{I18nProvider.formatMessage({ id: 'comment.actions.submit' })}</>}
            </SubmitButton>
          </StyledForm>
        )
      }}
    />
  )
}

Form.defaultProps = {
  initialValues: null,
  placeholder: undefined,
  wysiwygOffset: false,
  embed: false,
  autofocus: false,
  className: undefined,
  showMarkAsResolved: false,
  showIsAnonymous: false,
  hasAnonymousCommentInThread: false,
  canEditFile: true,
}

Form.propTypes = {
  user: PropTypes.shape().isRequired,
  session: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape(),
  placeholder: PropTypes.string,
  wysiwygOffset: PropTypes.bool,
  embed: PropTypes.bool,
  autofocus: PropTypes.bool,
  className: PropTypes.string,
  showMarkAsResolved: PropTypes.bool,
  showIsAnonymous: PropTypes.bool,
  hasAnonymousCommentInThread: PropTypes.bool,
  canEditFile: PropTypes.bool,
}

export default Form
