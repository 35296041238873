import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { filter } from 'graphql-anywhere'
import { Switch, Route } from 'react-router-dom'
import { Row, Col, Visible } from 'react-grid-system'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import I18nProvider from '@unowmooc/i18n'
import { mq } from 'styles-resources'
import { Container } from '@unowmooc/react-ui-kit'
import { mockParticipation } from 'modules/participant/utils'
import Helmet from 'components/HelmetIntl'
import PageQuery from 'components/PageQuery'
import useMe from 'hooks/useMe'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { FormattedMessage } from 'react-intl'
import SkipLinkAnchor from 'modules/skipLink/SkipLinkAnchor'
import Infos from './components/Infos'
import ProgramPage from './program'
import DiscussionsPage from './discussions'
import TrombinoscopePage from './trombinoscope'
import SkillsPage from './skills'
import VirtualClassroomsPage from './virtualClassrooms'

const Wrapper = styled.div`
  margin-bottom: 32px;
`

const PageCol = styled(Col)`
  ${mq.sm(css`
    margin-bottom: 30px;
  `)};
`

const CoursePage = ({ match: { url } }) => {
  const { sessionId } = useCourseContext()
  const { me } = useMe()
  const userGender = me?.gender

  return (
    <Wrapper>
      <Helmet title="page_titles.courses.course.title" />

      <PageQuery
        force
        query={gql`
        query CoursePage($id: ID!) {
          session(id: $id) {
            id
            canceledAt
            course {
              id
              slug
              hasFlexibleStart
            }
            ${toInlineFragment(DiscussionsPage.fragments.session)}
            ${toInlineFragment(TrombinoscopePage.fragments.session)}
            ${toInlineFragment(ProgramPage.fragments.session)}
            ${toInlineFragment(VirtualClassroomsPage.fragments.session)}
            ${toInlineFragment(Infos.fragments.session)}
            authenticatedParticipant {
              id
              ${toInlineFragment(ProgramPage.fragments.participation)}
            }
          }
        }
      `}
        variables={{
          id: sessionId,
        }}
        render={({ session }) => {
          const participation = session.authenticatedParticipant || mockParticipation(session)
          const { course } = session

          return (
            <>
              <Helmet title="page_titles.courses.course.course_title" values={{ title: course.title }} />
              <Container>
                <SkipLinkAnchor label={<FormattedMessage id="a11y.skip_to_content" />} anchor="content" priority={1} />
                <Switch>
                  <Route
                    exact
                    path={`${url}/${I18nProvider.translatePath('program')}`}
                    render={props => (
                      <Row>
                        <PageCol xs={12} md={8} lg={8}>
                          <ProgramPage
                            {...props}
                            session={filter(ProgramPage.fragments.session, session)}
                            participation={filter(ProgramPage.fragments.participation, participation)}
                          />
                        </PageCol>

                        <Visible xs sm lg xl>
                          <Col xs={12} lg={1} />
                        </Visible>
                        <Col xs={12} md={4} lg={3}>
                          <Infos session={filter(Infos.fragments.session, session)} gender={userGender} />
                        </Col>
                      </Row>
                    )}
                  />
                  <Route
                    exact
                    path={`${url}/${I18nProvider.translatePath('skills')}`}
                    render={() => <SkillsPage courseTitle={course.title} />}
                  />
                  <Route
                    exact
                    path={`${url}/${I18nProvider.translatePath('discussions')}`}
                    render={() => <DiscussionsPage session={filter(DiscussionsPage.fragments.session, session)} />}
                  />
                  <Route
                    exact
                    path={`${url}/${I18nProvider.translatePath('trombinoscope')}`}
                    render={() => <TrombinoscopePage session={filter(TrombinoscopePage.fragments.session, session)} />}
                  />
                  <Route
                    exact
                    path={`${url}/${I18nProvider.translatePath('virtual-classrooms')}`}
                    render={() => (
                      <VirtualClassroomsPage session={filter(VirtualClassroomsPage.fragments.session, session)} />
                    )}
                  />
                </Switch>
              </Container>
            </>
          )
        }}
      />
    </Wrapper>
  )
}

CoursePage.propTypes = {
  match: PropTypes.shape().isRequired,
  idSession: PropTypes.string.isRequired,
}

export default CoursePage
