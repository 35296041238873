import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, FieldLayout, Tag } from '@unowmooc/react-ui-kit'
import Checkbox from 'components/Checkbox'
import { FormattedMessage } from 'react-intl'
import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { colors } from '@unowmooc/themes'
import Select from 'components/Select'
import { captureException } from '@sentry/browser'
import qs from 'query-string'
import { Link } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import InputCopy from 'components/InputCopy'
import {
  checkParticipantToVirtualClassroom,
  getAttendanceKey,
  getVirtualClassroomSelectValue,
  getVirtualClassroomsOptions,
  initMatrix,
  setSelectedVirtualClassroom,
  uncheckParticipantToVirtualClassroom,
  useAttendancesReducer,
} from './attendancesReducer'
import ErrorModal from './ErrorModal'
import { getVirtualClassroomLabel } from './utils'
import config from 'config'

const virtualClassroomAttendancesQuery = gql`
  query VirtualClassroomAttendancesQuery($sessionId: ID!) {
    matrix: session(id: $sessionId) {
      id
      course {
        id
        slug
      }
      registeredParticipants {
        id
        hasUsedCpf
        user {
          id
          slug
          fullName
        }
      }
      virtualClassrooms {
        id
        sequenceNumber
        startDate
        isOnSite
      }
      virtualClassroomsAttendances {
        id
        participantId
        virtualClassroomId
      }
    }
  }
`

const setVirtualClassroomAttendanceMutation = gql`
  mutation setVirtualClassroomAttendance($attendanceInput: SetVirtualClassroomAttendanceInput!) {
    setVirtualClassroomAttendance(virtualClassroomAttendanceInput: $attendanceInput)
  }
`

const SummaryMessage = styled.p`
  margin-bottom: 24px;
`

const Table = styled('table', { shouldForwardProp: () => true })`
  width: 100%;
  margin-top: 26px;
`

const TableHeaderCol = styled.th`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: ${({ align }) => align};
  padding: 10px 12px;
`

const TableLine = styled.tr`
  height: 46px;
  &:nth-child(odd) {
    background-color: ${colors.whiteLilac};
  }
  &:hover {
    background-color: #faecd1;
  }
`

const TableUserFullNameCell = styled.td`
  padding-left: 12px;
`

const TableUserFullNameCellWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

const TableCheckboxCell = styled.td`
  text-align: center;
`

const InlineCheckbox = styled(Checkbox)`
  display: inline-flex;
`

const VirtualClassroomsPage = ({ location, sessionId }) => {
  const [errorModal, setErrorModal] = useState(null)
  const [attendances, dispatch] = useAttendancesReducer()
  const { data } = useQuery(virtualClassroomAttendancesQuery, { variables: { sessionId }, fetchPolicy: 'network-only' })
  const [setVirtualClassroomAttendance] = useMutation(setVirtualClassroomAttendanceMutation)

  useEffect(() => {
    if (!data) {
      return
    }

    const orderedVirtualClassroomsByNumberAndStartDate = data.matrix.virtualClassrooms.sort((x, y) => {
      if (x.sequenceNumber !== y.sequenceNumber) {
        return x.sequenceNumber - y.sequenceNumber
      }

      return x.startDate - y.startDate
    })

    const orderedParticipantsByFullName = data.matrix.registeredParticipants.sort((x, y) =>
      x.user.fullName.toLowerCase() > y.user.fullName.toLowerCase() ? 1 : -1,
    )

    const { vc } = qs.parse(location.search)
    const selectedVirtualClassroom = orderedVirtualClassroomsByNumberAndStartDate.find(
      virtualClassroom => virtualClassroom.id === vc,
    )

    dispatch(
      initMatrix({
        virtualClassrooms: orderedVirtualClassroomsByNumberAndStartDate,
        participants: orderedParticipantsByFullName,
        virtualClassroomsAttendances: data.matrix.virtualClassroomsAttendances,
        selectedVirtualClassroom,
      }),
    )
  }, [data])

  const check = ({ participant, virtualClassroom }) => {
    dispatch(
      checkParticipantToVirtualClassroom({
        participantId: participant.id,
        virtualClassroomId: virtualClassroom.id,
      }),
    )

    const attendanceInput = { participantId: participant.id, virtualClassroomId: virtualClassroom.id, present: true }
    setVirtualClassroomAttendance({ variables: { attendanceInput } }).catch(error => {
      captureException(new Error(`[Expert virtual classroom attendance]: ${error.toString()}`))
      setErrorModal({ participant, virtualClassroom, present: true })
    })
  }

  const uncheck = ({ participant, virtualClassroom }) => {
    dispatch(
      uncheckParticipantToVirtualClassroom({
        participantId: participant.id,
        virtualClassroomId: virtualClassroom.id,
      }),
    )

    const attendanceInput = { participantId: participant.id, virtualClassroomId: virtualClassroom.id, present: false }
    setVirtualClassroomAttendance({ variables: { attendanceInput } }).catch(error => {
      captureException(new Error(`[Expert virtual classroom attendance]: ${error.toString()}`))
      setErrorModal({ participant, virtualClassroom, present: false })
    })
  }

  const handleVirtualClassroomSelectorChange = virtualClassroom => {
    // L'option 'Toutes les CV' est définie avec un Id null.
    const newSelectedVirtualClassroom = virtualClassroom.id ? virtualClassroom : null
    dispatch(setSelectedVirtualClassroom({ virtualClassroom: newSelectedVirtualClassroom }))

    window.history.replaceState(null, null, `?vc=${virtualClassroom.id ?? ''}`)
  }

  if (!data) {
    return null
  }

  // eslint-disable-next-line compat/compat
  const copyVirtualClassroomLink = `${window.location.origin}${I18nProvider.getLinkRoute(
    `/courses/{slugCourse}/{sessionId}/virtual-classrooms`,
    {
      slugCourse: data.matrix.course.slug,
      sessionId: data.matrix.id,
    },
  )}`

  return (
    <Box>
      <ErrorModal errorModal={errorModal} />
      <SummaryMessage>
        <FormattedMessage id="expert.virtual_classroom.summary_message" />
      </SummaryMessage>
      <FieldLayout label={<FormattedMessage id="expert.virtual_classroom.page_url" />}>
        <InputCopy id="expert-virtual-classroom-link" value={copyVirtualClassroomLink} />
      </FieldLayout>
      <FieldLayout label={<FormattedMessage id="expert.virtual_classroom.select_filter" />}>
        <Select
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex={1}
          value={getVirtualClassroomSelectValue(attendances)}
          options={getVirtualClassroomsOptions(attendances)}
          onChange={handleVirtualClassroomSelectorChange}
          placeholder={<FormattedMessage id="expert.virtual_classroom.option_label_all" />}
        />
      </FieldLayout>
      <Table cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <TableHeaderCol align="left">
              <FormattedMessage id="expert.virtual_classroom.participant_col_label" />
            </TableHeaderCol>
            {attendances.selectedVirtualClassrooms.map(virtualClassroom => (
              <TableHeaderCol align="center">
                {getVirtualClassroomLabel(virtualClassroom, { inline: false })}
                <br />
                (
                <FormattedMessage
                  id="expert.virtual_classroom.counter"
                  values={{
                    counter: attendances.attendances.filter(virtualClassroomAttendance =>
                      new RegExp(`^\\d*_${virtualClassroom.id}$`).test(virtualClassroomAttendance),
                    ).length,
                  }}
                />
                )
                {virtualClassroom.isOnSite && (
                  <span>
                    <br />
                    <a
                      href={config.env.GRAPHQL_API_URL + `/virtualclassroom/${virtualClassroom.id}/attendance-sheet-pdf`}
                      target="attestation-pdf"
                    >
                      Feuille de présence
                    </a>
                  </span>
                )}
              </TableHeaderCol>
            ))}
          </tr>
        </thead>
        <tbody>
          {attendances.participants.map(participant => (
            <TableLine key={participant.id}>
              <TableUserFullNameCell>
                <TableUserFullNameCellWrapper>
                  {participant.user.slug && (
                    <Link
                      to={I18nProvider.getLinkRoute('/profile/{userSlug}/{userId}', {
                        userSlug: participant.user.slug,
                        userId: participant.user.id,
                      })}
                    >
                      {participant.user.fullName || <FormattedMessage id="commons.unspecified" />}
                    </Link>
                  )}
                  {!participant.user.slug &&
                    (participant.user.fullName || <FormattedMessage id="commons.unspecified" />)}
                  {participant.hasUsedCpf && (
                    <Tag theme="highlight" small>
                      <FormattedMessage id="commons.cpf" />
                    </Tag>
                  )}
                </TableUserFullNameCellWrapper>
              </TableUserFullNameCell>
              {attendances.selectedVirtualClassrooms.map((virtualClassroom, virtualClassroomIndex) => (
                <TableCheckboxCell key={virtualClassroom.id}>
                  <InlineCheckbox
                    tabIndex={virtualClassroomIndex + 1}
                    onChange={() => {
                      const attendanceId = attendances.attendances.includes(
                        getAttendanceKey({
                          participantId: participant.id,
                          virtualClassroomId: virtualClassroom.id,
                        }),
                      )

                      if (attendanceId) {
                        uncheck({
                          participant,
                          virtualClassroom,
                        })
                      } else {
                        check({
                          participant,
                          virtualClassroom,
                        })
                      }
                    }}
                    checked={attendances.attendances.includes(
                      getAttendanceKey({
                        participantId: participant.id,
                        virtualClassroomId: virtualClassroom.id,
                      }),
                    )}
                  />
                </TableCheckboxCell>
              ))}
            </TableLine>
          ))}
        </tbody>
      </Table>
    </Box>
  )
}

VirtualClassroomsPage.propTypes = {
  sessionId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
}

export default VirtualClassroomsPage
