import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { getGlobalExpertGenderFromUseMe } from 'business/expert'

const GET_ME = gql`
  query Me {
    me {
      id
      slug
      role
      email
      locale
      firstName
      lastName
      fullName
      isLms
      nbSupervisions
      nbAnimations
      nbParticipations
      canUpdatePassword
      conversationsParticipantEnabled
      conversationsAnimatorEnabled
      virtualclassroomInputEnabled
      hasPossibleIssueWithAvatar
      fileStack
      gender
      account {
        id
        name
      }
      avatar {
        id
        mimeType
        fileStackId
        secureUrl
        url
        policy
        signature
      }
      animations {
        session {
          id
        }
      }
      setting {
        id
      }
      account {
        showCatalog
      }
      participants {
        id
        session {
          id
          mainAnimator {
            id
            gender
          }
          startAt
          endAt
          course {
            id
            title
            slug
          }
          forms {
            commonSkillsFinalForm {
              id
            }
            specificSkillsFinalForm {
              id
            }
          }
        }
        formAdvancementStates {
          answeredCommonSkillsFinalForm
          answeredSpecificSkillsFinalForm
        }
      }
    }
  }
`

const useMe = () => {
  const { loading, data, ...rest } = useQuery(GET_ME)

  let me = null
  if (!loading) {
    me = data?.me ?? null
  }

  const globalExpertGender = getGlobalExpertGenderFromUseMe(me)

  return { loading, me, globalExpertGender, ...rest }
}

export default useMe
