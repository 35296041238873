import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Visible } from 'react-grid-system'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import Headroom from 'react-headroom'
import I18nProvider from '@unowmooc/i18n'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { ButtonSkin } from '@unowmooc/buttons'
import { Can } from 'store/AbilityProvider'
import useMe from 'hooks/useMe'
import * as HeaderSession from 'portal-components/HeaderSession'
import Title from './Title'
import UserDropdown from './UserDropdown'
import HamburgerButton from './HamburgerButton'
import HelpDropdown from './HelpDropdown'

const Wrapper = styled.header`
  display: flex;
  height: 60px;
  line-height: 60px;
  background-color: ${({ theme: { colors } }) => colors.white};
  box-shadow: ${({ floating, theme: { colors } }) => floating && `0 1px 0 0 ${colors.whisper}`};
`

const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  ${mq.xs(css`
    align-items: stretch;
  `)}
`

const StyledHamburgerButton = styled(HamburgerButton)`
  margin: 22px 20px 0 0;
`

const StyledUserDropdown = styled(UserDropdown)`
  margin-right: 20px;
`

const StyledHelpDropdown = styled(HelpDropdown)`
  margin-right: 8px;
`

const ExpertButton = styled(ButtonSkin, { shouldForwardProp: () => true })`
  margin-right: 8px;
  white-space: nowrap;
  & > span {
    display: flex;
    align-items: center;
  }
  .icon {
    font-size: 21px;
  }
`

const HEADER_LOGO_WIDTH = 180
const HEADER_RIGHT_ACTIONS_WIDTH = 550

const HeaderSessionTargetContainer = styled.div`
  width: calc(100% - ${HEADER_LOGO_WIDTH + HEADER_RIGHT_ACTIONS_WIDTH}px);
  display: flex;
  align-items: center;
`

const Header = ({ menuMobileOpen, onMenuMobileToggle, className }) => {
  const [floating, setFloating] = useState(false)
  const { globalExpertGender, me } = useMe()

  const isLms = me?.isLms

  if (isLms) {
    return null
  }

  return (
    <>
      <Visible xs sm>
        <Headroom onPin={() => setFloating(true)} onUnfix={() => setFloating(false)} style={{ zIndex: 100 }}>
          <Wrapper floating={floating} className={className}>
            <Title />
            <HeaderSession.Target as={HeaderSessionTargetContainer} />
            <Right>
              <StyledHamburgerButton onClick={onMenuMobileToggle} active={menuMobileOpen} />
            </Right>
          </Wrapper>
        </Headroom>
      </Visible>

      <Visible md lg xl>
        <Wrapper floating={floating} className={className}>
          <Title />
          <HeaderSession.Target as={HeaderSessionTargetContainer} />
          <Right>
            <Can I="access-domain" of="conversations">
              <ExpertButton
                tag={Link}
                to={I18nProvider.getLinkRoute('/conversations')}
                icon="mail"
                label={<FormattedMessage id="header_menu.contact_expert" values={{ globalExpertGender }} />}
                size="small"
                data-tracking-id="header_menu.click_on_expert_contact_button_link"
              />
            </Can>
            <StyledHelpDropdown />
            <StyledUserDropdown />
          </Right>
        </Wrapper>
      </Visible>
    </>
  )
}

Header.defaultProps = {
  className: undefined,
}

Header.propTypes = {
  onMenuMobileToggle: PropTypes.func.isRequired,
  menuMobileOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

export default Header
