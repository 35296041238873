import React from 'react'
import PropTypes from 'prop-types'
import { Visible } from 'react-grid-system'
import { Navbar } from '@unowmooc/react-ui-kit'
import StreamlineIcon from '@unowmooc/icons'
import { Link } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { colors } from 'styles-resources'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import useMe from 'hooks/useMe'

const StyledNavbar = styled(Navbar)`
  margin-bottom: 0px;
`

const MobileNav = styled.nav`
  display: flex;
  height: 50px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.whisper};
  border-bottom: 1px solid ${colors.whisper};
`

const MobileNavItem = styled(Link, { shouldForwardProp: prop => prop !== 'active' })`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  border-right: 1px solid ${colors.whisper};
  color: ${({ active }) => (active ? colors.unowGreen : colors.shipGray)};
  font-size: 24px;

  &:hover {
    text-decoration: none;
  }

  &::after {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ active }) => (active ? colors.unowGreen : colors.mischka)};
  }

  &:last-child {
    border-right: 0;
  }
`

const NavigationItemWithAutoActive = ({ component: Component, pathname, to, activePaths, ...rest }) => {
  const nextActivePaths = [...activePaths, to]
  const isActive = nextActivePaths.some(path => pathname.indexOf(path) !== -1)

  return <Component active={isActive} to={to} data-cy="page-tab" {...rest} />
}

NavigationItemWithAutoActive.defaultProps = {
  activePaths: [],
}

NavigationItemWithAutoActive.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  pathname: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  activePaths: PropTypes.arrayOf(PropTypes.string),
}

const Navigation = ({ baseUrl, pathname }) => {
  const {
    hasBic,
    participantId,
    courseIdentifier,
    sessionIdentifier,
    hasVirtualClassroom,
    hasOnSiteVirtualClassroom,
  } = useCourseContext()

  const { me } = useMe()

  const isLms = me?.isLms

  if (isLms) {
    return null
  }

  // On s'assure que la session comporte un BIC et qu'il s'agit bien d'un utilisateur avec une participation
  // Si pas de participantId alors il peut s'agir d'un expert ou d'un admin sans participation
  const canDisplaySkillTab = hasBic && !!participantId

  return (
    <>
      <Visible md lg xl>
        <StyledNavbar>
          <NavigationItemWithAutoActive
            component={Navbar.NavItem}
            tag={Link}
            pathname={pathname}
            to={`${baseUrl}/${I18nProvider.translatePath('program')}`}
            label={<FormattedMessage id="courses.course.tabs.home" />}
            data-tracking-id="session.click_on_program_page"
            data-tracking-values={JSON.stringify({
              courseIdentifier,
              sessionIdentifier,
              clickedTab: 'program',
            })}
          />
          <NavigationItemWithAutoActive
            component={Navbar.NavItem}
            tag={Link}
            pathname={pathname}
            to={`${baseUrl}/${I18nProvider.translatePath('lessons')}`}
            label={<FormattedMessage id="courses.course.tabs.training" />}
            data-tracking-id="session.click_on_program_page"
            data-tracking-values={JSON.stringify({
              courseIdentifier,
              sessionIdentifier,
              clickedTab: 'lessons',
            })}
          />
          {canDisplaySkillTab && (
            <NavigationItemWithAutoActive
              component={Navbar.NavItem}
              tag={Link}
              pathname={pathname}
              to={`${baseUrl}/${I18nProvider.translatePath('skills')}`}
              label={<FormattedMessage id="courses.course.tabs.skills" />}
              data-tracking-id="session.click_on_program_page"
              data-tracking-values={JSON.stringify({
                courseIdentifier,
                sessionIdentifier,
                clickedTab: 'skills',
              })}
              activePaths={[
                I18nProvider.translatePath('final_skills_form'),
                I18nProvider.translatePath('initial_skills_form'),
              ]}
            />
          )}
          {hasVirtualClassroom && (
            <NavigationItemWithAutoActive
              component={Navbar.NavItem}
              tag={Link}
              pathname={pathname}
              to={`${baseUrl}/${I18nProvider.translatePath('virtual-classrooms')}`}
              label={
                <FormattedMessage
                  id={
                    hasOnSiteVirtualClassroom
                      ? 'courses.course.tabs.virtual_classrooms_and_on_site'
                      : 'courses.course.tabs.virtual_classrooms'
                  }
                />
              }
              data-tracking-id="session.click_on_virtual_classrooms_page"
              data-tracking-values={JSON.stringify({
                courseIdentifier,
                sessionIdentifier,
                clickedTab: 'virtual_classrooms',
              })}
            />
          )}
          <NavigationItemWithAutoActive
            component={Navbar.NavItem}
            tag={Link}
            pathname={pathname}
            to={`${baseUrl}/${I18nProvider.translatePath('discussions')}`}
            label={<FormattedMessage id="courses.course.tabs.discussions" />}
            data-tracking-id="session.click_on_program_page"
            data-tracking-values={JSON.stringify({
              courseIdentifier,
              sessionIdentifier,
              clickedTab: 'discussions',
            })}
          />
          <NavigationItemWithAutoActive
            component={Navbar.NavItem}
            tag={Link}
            pathname={pathname}
            to={`${baseUrl}/${I18nProvider.translatePath('trombinoscope')}`}
            label={<FormattedMessage id="courses.course.tabs.trombinoscope" />}
            data-tracking-id="session.click_on_program_page"
            data-tracking-values={JSON.stringify({
              courseIdentifier,
              sessionIdentifier,
              clickedTab: 'trombinoscope',
            })}
          />
        </StyledNavbar>
      </Visible>
      <Visible xs sm>
        <MobileNav>
          <NavigationItemWithAutoActive
            component={MobileNavItem}
            pathname={pathname}
            to={`${baseUrl}/${I18nProvider.translatePath('program')}`}
            data-tracking-id="session.click_on_program_page"
            data-tracking-values={JSON.stringify({
              courseIdentifier,
              sessionIdentifier,
              clickedTab: 'program',
            })}
          >
            <StreamlineIcon icon="home" />
          </NavigationItemWithAutoActive>
          <NavigationItemWithAutoActive
            component={MobileNavItem}
            pathname={pathname}
            to={`${baseUrl}/${I18nProvider.translatePath('lessons')}`}
            data-tracking-id="session.click_on_program_page"
            data-tracking-values={JSON.stringify({
              courseIdentifier,
              sessionIdentifier,
              clickedTab: 'lessons',
            })}
          >
            <StreamlineIcon icon="graduation-hat" />
          </NavigationItemWithAutoActive>
          {hasVirtualClassroom && (
            <NavigationItemWithAutoActive
              component={MobileNavItem}
              pathname={pathname}
              to={`${baseUrl}/${I18nProvider.translatePath('virtual-classrooms')}`}
              data-tracking-id="session.click_on_virtual_classrooms_page"
              data-tracking-values={JSON.stringify({
                courseIdentifier,
                sessionIdentifier,
                clickedTab: 'virtual_classrooms',
              })}
            >
              <StreamlineIcon icon="video-meeting-group" />
            </NavigationItemWithAutoActive>
          )}
          <NavigationItemWithAutoActive
            component={MobileNavItem}
            pathname={pathname}
            to={`${baseUrl}/${I18nProvider.translatePath('discussions')}`}
            data-tracking-id="session.click_on_program_page"
            data-tracking-values={JSON.stringify({
              courseIdentifier,
              sessionIdentifier,
              clickedTab: 'discussions',
            })}
          >
            <StreamlineIcon icon="chat" />
          </NavigationItemWithAutoActive>
          <NavigationItemWithAutoActive
            component={MobileNavItem}
            pathname={pathname}
            to={`${baseUrl}/${I18nProvider.translatePath('trombinoscope')}`}
            data-tracking-id="session.click_on_program_page"
            data-tracking-values={JSON.stringify({
              courseIdentifier,
              sessionIdentifier,
              clickedTab: 'trombinoscope',
            })}
          >
            <StreamlineIcon icon="user-group" />
          </NavigationItemWithAutoActive>
        </MobileNav>
      </Visible>
    </>
  )
}

Navigation.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
}

export default Navigation
